export const stakingAddress: { [key: number]: string } = {
  56: "0xD2E7d07acF803aeFF305643946c9e459a8666912",
};
export const tokenAddress = {
  56: "0x84bdC996493683c0518eeEBEf37Cd0D1Ed731582",
};

export const RPC_URL = {
  56: "https://bsc-dataseed3.binance.org",
};
