import React from "react";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitterIcon.svg";
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegramIcon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/icons/instagram.svg";
import { ReactComponent as YouTubeIcon } from "../../assets/icons/youtube.svg";

// import { ReactComponent as AppStoreIcon } from "../../assets/icons/appstore.svg";
// import { ReactComponent as TikTok } from "../../assets/icons/tiktok.svg";
// import { ReactComponent as GooglePayIcon } from "../../assets/icons/googleplay.svg";
import { ReactComponent as DiscordIcon } from "../../assets/icons/discord.svg";
import { ReactComponent as MediumIcon } from "../../assets/icons/mediumIcon.svg";

import "./Footer.scss";
import { Link } from "react-router-dom";

const renderLinks = (
  <div className="links flex-item">
    {/* <Link to="/">General Statement</Link> */}
    {/* <Link to="/">Legal Advice</Link> */}
    <Link to="https://exchange.nexuschain.app/">CEX</Link>
    <Link to="https://dapp.nexuschain.app/">DAO</Link>
    <Link to="https://scan.nexuschain.app/">Explorer</Link>
    <Link to="https://nexuschain.app/">Website</Link>

    <Link to="https://wp.nexuschain.app/">Docs</Link>

    {/* <Link to="/">Contact</Link> */}
  </div>
);
const Footer: React.FC = () => {
  return (
    <div className="footer-wrapper pad">
      <div className="mx">
        <div className="footer-container">
          
          <div className="footer-content flex-item">
            <div className="media flex-item">
              <a href="https://fomocoin.me/" target="_blank">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe" viewBox="0 0 16 16"> <path d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm7.5-6.923c-.67.204-1.335.82-1.887 1.855A7.97 7.97 0 0 0 5.145 4H7.5V1.077zM4.09 4a9.267 9.267 0 0 1 .64-1.539 6.7 6.7 0 0 1 .597-.933A7.025 7.025 0 0 0 2.255 4H4.09zm-.582 3.5c.03-.877.138-1.718.312-2.5H1.674a6.958 6.958 0 0 0-.656 2.5h2.49zM4.847 5a12.5 12.5 0 0 0-.338 2.5H7.5V5H4.847zM8.5 5v2.5h2.99a12.495 12.495 0 0 0-.337-2.5H8.5zM4.51 8.5a12.5 12.5 0 0 0 .337 2.5H7.5V8.5H4.51zm3.99 0V11h2.653c.187-.765.306-1.608.338-2.5H8.5zM5.145 12c.138.386.295.744.468 1.068.552 1.035 1.218 1.65 1.887 1.855V12H5.145zm.182 2.472a6.696 6.696 0 0 1-.597-.933A9.268 9.268 0 0 1 4.09 12H2.255a7.024 7.024 0 0 0 3.072 2.472zM3.82 11a13.652 13.652 0 0 1-.312-2.5h-2.49c.062.89.291 1.733.656 2.5H3.82zm6.853 3.472A7.024 7.024 0 0 0 13.745 12H11.91a9.27 9.27 0 0 1-.64 1.539 6.688 6.688 0 0 1-.597.933zM8.5 12v2.923c.67-.204 1.335-.82 1.887-1.855.173-.324.33-.682.468-1.068H8.5zm3.68-1h2.146c.365-.767.594-1.61.656-2.5h-2.49a13.65 13.65 0 0 1-.312 2.5zm2.802-3.5a6.959 6.959 0 0 0-.656-2.5H12.18c.174.782.282 1.623.312 2.5h2.49zM11.27 2.461c.247.464.462.98.64 1.539h1.835a7.024 7.024 0 0 0-3.072-2.472c.218.284.418.598.597.933zM10.855 4a7.966 7.966 0 0 0-.468-1.068C9.835 1.897 9.17 1.282 8.5 1.077V4h2.355z"/> </svg>
              </a>
              <a href="https://twitter.com/itsfomotime" target="_blank">
                <TwitterIcon />
              </a>
              <a href="https://t.me/itsfomotime" target="_blank">
                <TelegramIcon />
              </a>
               <a href="https://wp.fomocoin.me/"target="_blank"><svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-brand-github" width="24" height="24" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round"> <path stroke="none" d="M0 0h24v24H0z" fill="none"/> <path d="M9 19c-4.3 1.4 -4.3 -2.5 -6 -3m12 5v-3.5c0 -1 .1 -1.4 -.5 -2c2.8 -.3 5.5 -1.4 5.5 -6a4.6 4.6 0 0 0 -1.3 -3.2a4.2 4.2 0 0 0 -.1 -3.2s-1.1 -.3 -3.5 1.3a12.3 12.3 0 0 0 -6.2 0c-2.4 -1.6 -3.5 -1.3 -3.5 -1.3a4.2 4.2 0 0 0 -.1 3.2a4.6 4.6 0 0 0 -1.3 3.2c0 4.6 2.7 5.7 5.5 6c-.6 .6 -.6 1.2 -.5 2v3.5" /> </svg></a>

            </div>

            <div className="footer-second-content flex-item">
              {/* <div className="app-icon flex-item">
                <AppStoreIcon />
                <GooglePayIcon />
              </div> */}
              <div className="list-navigation">

                <div>
                  <p className="para">
                    ©                      <span style={{ color: '#ff6900' }} >
$FOMO </span> 2023
                    {/* <span>
                      <a href="/">2.57.0 - info@8bitchain.io</a>
                    </span> */}
                    
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
